<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" >
      <el-breadcrumb-item>配置</el-breadcrumb-item>
      <el-breadcrumb-item>app收益</el-breadcrumb-item>
    </el-breadcrumb>
    <br />
    <el-row>
      <!-- <el-button type="primary" @click="handleCreate">添加</el-button> -->
      <el-button type="primary" @click="handleFoms">手动录入</el-button>
      <el-date-picker

          style="margin-left: 30px"
          v-model="listQuery.dateYMd"
          value-format="yyyy-MM-dd"
          type="date"
          @change="(value) => datechange(value)"
          placeholder="选择日期">
      </el-date-picker>

      <el-select v-model="listQuery.name" value-key="id" clearable   placeholder="app" style="margin-left: 30px" @change="qappIdChange" >
        <el-option
            v-for="item in appOptions"
            :key="item.id"
            :label="item.name"
            :value="item">
        </el-option>
      </el-select>

      <el-select v-model="listQuery.adId" placeholder="广告位" clearable style="margin-left: 30px"   @change="qadIdChange" >
        <el-option
            v-for="item in qadOptions"
            :key="item.id"
            :label="item.adName"
            :value="item.id">
        </el-option>
      </el-select>
    </el-row>

    <!--表单 BEGIN -->
    <el-dialog title="手动录入" :visible.sync="dialogFormVisible1">
      <el-form ref="dataForms" :model="fomrs" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">

        <el-form-item label="应用" prop="appId">
          <el-select v-model="fomrs.appId" value-key="id" @change="appIdChange"  style="width:500px"   placeholder="应用">
            <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="广告位" prop="adId">
          <el-select v-model="fomrs.adId" value-key="id" style="width:500px" placeholder="广告位">
            <el-option
                v-for="item in adOptions"
                :key="item.id"
                :label="item.adName"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="系统类型" prop="sysType">
          <el-select v-model="fomrs.sysType" style="width:500px" placeholder="系统类型">
            <el-option
                v-for="item in adtypeList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="日期" prop="dateYmd" label-width="100px">
          <el-date-picker
              v-model="fomrs.dateYMd"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus1==='create'?createDataTab():''">
          保存
        </el-button>
      </div>
    </el-dialog>

    <!--表单 BEGIN -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">

        <el-form-item label="应用" prop="appId">
          <el-select v-model="temp.appName" value-key="id" :disabled="dialogStatus === 'update'" @change="appIdChange"  style="width:500px"   placeholder="应用">
            <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="广告位" prop="adId">
          <el-select v-model="temp.adName" value-key="id" :disabled="dialogStatus === 'update'"  style="width:500px" placeholder="广告位">
            <el-option
                v-for="item in adOptions"
                :key="item.id"
                :label="item.adName"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="系统类型" prop="sysType">
          <el-select v-model="temp.sysType" style="width:500px" placeholder="系统类型">
            <el-option
                v-for="item in adtypeList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="日期" prop="dateYmd" label-width="100px">

          <el-date-picker
              v-model="temp.dateYmd"
              v-bind:disabled="dialogStatus === 'update'"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="实际收入" prop="actualRevenue">
          <el-input v-model="temp.actualRevenue" @change="changeMoney"/>
        </el-form-item>
        <el-form-item label="实收比率(千分比)" prop="actualRatio">
          <el-input v-model="temp.actualRatio" @change="changeMoney"/>
        </el-form-item>
        <el-form-item label="展示收入" prop="showRevenue">
          <el-input v-model="temp.showRevenue" disabled />
        </el-form-item>
        <el-form-item label="ecpm" prop="ecpm">
          <el-input v-model="temp.ecpm" disabled />
        </el-form-item>
        <el-form-item label="请求量" prop="askNumber">
          <el-input v-model="temp.askNumber" />
        </el-form-item>
        <el-form-item label="曝光量" prop="exposureNumber">
          <el-input v-model="temp.exposureNumber" @change="changeExposure"/>
        </el-form-item>
        <!-- <el-form-item label="曝光率(百分比)" prop="exposureRate">
          <el-input v-model="temp.exposureRate" disabled/>
        </el-form-item> -->
        <el-form-item label="点击量" prop="clickNumber">
          <el-input v-model="temp.clickNumber" />
        </el-form-item>
        <!-- <el-form-item label="点击率(百分比)" prop="clickRate">
          <el-input v-model="temp.clickRate" disabled/>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>

    <!--表单 END -->
    <br />
    <div style="background: #fff ">
    <el-table
        v-loading="loadings"
        ref="singleTable"
        :data="tableData"
        :span-method="objectSpanMethod"
        border
        fit
        style="width: 100%;height: 100%"
    >
      <el-table-column
          fixed
          fit
          align="center"
          type="index"
          min-width="20">
      </el-table-column>

      <el-table-column prop="appName" label="app名称" min-width="40" />

      <el-table-column prop="adName" label="广告位名称" min-width="60" />

      <el-table-column prop="sysType" label="系统类型" min-width="40">
        <template  slot-scope="scope">
          {{ scope.row.sysType === 1 ? 'Android' : scope.row.sysType === 2 ? 'Ios' : scope.row.sysType === 3 ?'Miniprogram' : scope.row.sysType === 4 ? 'H5' : ''}}
        </template>
      </el-table-column>

      <el-table-column prop="dateYmd" label="日期" min-width="40" />

      <el-table-column prop="actualRevenue" label="实际收入" min-width="40" />

      <el-table-column prop="actualRatio" label="实收（千分比）" min-width="40" />

      <el-table-column prop="showRevenue" label="展示收入" min-width="40" />

      <el-table-column prop="ecpm" label="ecpm" min-width="40" />

      <el-table-column prop="askNumber" label="请求量" min-width="40" />

      <el-table-column prop="exposureNumber" label="曝光量" min-width="40" />

      <el-table-column prop="exposureRate" label="曝光率（百分比）" min-width="40" >
        <template  slot-scope="scope">
          {{ scope.row.exposureRate ? scope.row.exposureRate : 0}}%
        </template>
      </el-table-column>

      <el-table-column prop="clickNumber" label="点击量" min-width="40" />

      <el-table-column prop="clickRate" label="点击率（百分比）" min-width="40" >
        <template  slot-scope="scope">
          {{ scope.row.clickRate ? scope.row.clickRate : 0}}%
        </template>
      </el-table-column>


      <el-table-column label="操作" align="center" min-width="60" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
      <el-table-column label="是否发布" align="center" min-width="50">
        <template slot-scope="scope">
          <el-switch
            style="display:block;"
            v-model="scope.row.cp"
            @change="change(scope.row,scope.$index)"
            active-text="是"
            :disabled="scope.row.cp"
            inactive-text="否">
          </el-switch>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                   background @current-change="handleCurrentChange" :page-size="listQuery.size" layout="total,prev, pager, next" :total="total">
    </el-pagination>
    <div style="height: 30px;" />

    </div>


  </div>
</template>

<script>
import { saveApi,delApi, listApi2 } from '@/api/appProfit'

import {listAllApi} from "@/api/apps";
import { listAllByAppId, publishAd, createTabs } from '@/api/ads'
export default {
  name: "appProfit",
  components: {  },
  inject: ['load','closeLoad'],
  data() {
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
        dateYMd:'',
        appId:'',
        adId:''
      },
      switchValue1: false,
      dialogFormVisible: false,
      dialogFormVisible1:false,
      dialogStatus: '',
      dialogStatus1:'',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        bgId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '不能为空', trigger: 'blur' }],
        sex: [{ required: true, message: '不能为空', trigger: 'blur' }],
        phone: [{ required: true, message: '不能为空', trigger: 'blur' }],
        wechat: [{ required: true, message: '不能为空', trigger: 'blur' }],
        qq: [{ required: true, message: '不能为空', trigger: 'blur' }],
        email: [{ required: true, message: '不能为空', trigger: 'blur' }],

      },

      temp: {
        id: undefined,
        appId: '',
        adId: '',
        dateYMd: '',
        actualRevenue: '',
        actualRatio: '',
        showRevenue: '',
        ecpm: '',
        askNumber: '',
        exposureNumber: '',
        clickNumber: '',
        clickRate: '',
        adName: '',
        sysType:'',
        exposureRate:''
      },
      fomrs:{
        appId: '',
        dateYMd: '',
        adId:'',
        sysType:''
      },
      adtypeList:[
        {
          id: 1,
          label: 'Android'
        },
        {
          id: 2,
          label: 'Ios'
        },
        {
          id: 3,
          label: 'Miniprogram'
        },
        {
          id: 4,
          label: 'H5'
        }
      ],
      loadings:false,
      appName:'',
      tableData: [],
      appOptions:[],
      adOptions:[],
      companyOptions:[],
      qadOptions:[],
      testArr1: [],
      testArr2: [],
      testArr3: [],
      testPosition1: 0,
      testPosition2: 0,
      testPosition3:0,
      sexOptions:[{
        id: 1,
        label: '男'
      },{
        id: 2,
        label: '女'
      }],
    }
  },
  created() {
    this.getList();
    this.getInitOptions();
  },
  methods:{
    // setAppId(data) {
    //   console.log(data)
    //   this.fomrs.appId = data.id
    // },
    changeMoney() {
      if(this.temp.actualRatio >= 1000) {
        this.temp.actualRatio = 1000
      }
      if(this.temp.actualRatio <= 0) {
        this.temp.actualRatio = 10
      }
      this.temp.showRevenue = (this.temp.actualRevenue * (this.temp.actualRatio / 1000)).toFixed(2)
    },
    changeExposure() {
      this.temp.ecpm = ((this.temp.showRevenue / this.temp.exposureNumber ) * 1000).toFixed(2)
    },
    appIdChange(val){
      console.info("广告位")
      console.info(val)
      //广告位
      this.temp.adId='';
      console.log(val.id)
      listAllByAppId({appId: val.id}).then(response => {
        this.adOptions = response.body
      })
    },
    qappIdChange(val){
      console.info(val.id)
      //广告位
      this.listQuery.adId='';

      this.listQuery.current = 1;
      this.listQuery.appId = val.id
      this.appName = val.name
      
      listAllByAppId({appId: val.id}).then(response => {
        console.log(response)
        this.qadOptions = response.body
        this.getList();
      })

    },
    qadIdChange(val){
      console.info(val)
      this.listQuery.current = 1;
      this.getList();

    },
    getInitOptions(){
      listAllApi({current: 1,size: 1000,}).then(response => {
        this.appOptions = response.body.records
      })
    },
    change(data,val) {
       let statusVal = data.cp
       console.log(this.tableData[val].publish)
       this.tableData[val].cp = statusVal ? false : true
       let confVal = statusVal ? 0 : 1
       console.log(this.tableData[val].publish)
       this.$confirm('是否确认发布?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.load()
        this.loadings = true
        this.tableData[val].cp = confVal ? false : true
        this.tableData[val].publish = confVal ? 0 : 1
        let para = {
          appId:data.appId,
          dateYMd:data.dateYmd,
          sysType:data.sysType
        }
        publishAd(para).then(response => {
          if(response.code === 1) {
              this.getList()
          }
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: ''
        // });
      });
      // console.log(data)
    },
    rowspan(spanArr, position, spanName,vals) {
      this.tableData.forEach((item, index) => {
        if (index === 0) {
          spanArr.push(1);
          position = 0;
        } else {
          if (
            this.tableData[index][spanName] ===
            this.tableData[index - 1][spanName] && 
            this.tableData[index][vals] === 
            this.tableData[index - 1][vals]
          ) {
            spanArr[position] += 1;
            spanArr.push(0);
          } else {
            spanArr.push(1);
            position = index;
          }
        }
      });
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 1) {
        const _row = this.testArr1[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      if (columnIndex === 4) {
        const _row = this.testArr2[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
      if (columnIndex === 15) {
        const _row = this.testArr3[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    getList() {
      this.loadings = true
      listApi2(this.listQuery).then(response => {
        // this.closeLoad()
        this.loadings = false
        let datas = response.body.records
        datas.forEach((item)=>{
          item.cp = item.publish ? true : false
        })
        this.tableData = datas
        this.total = response.body.total
        console.log(this.tableData)
        this.testArr1 = []
        this.testArr2 = []
        this.testArr3 = []
        this.testPosition1 = 0
        this.testPosition2 = 0
        this.testPosition3 = 0
        this.rowspan(this.testArr1, this.testPosition1, "appName","dateYmd");
        this.rowspan(this.testArr2, this.testPosition2, "appName","dateYmd");
        this.rowspan(this.testArr3, this.testPosition3, "appName","dateYmd");
        // if(response.body.total) {
        //   this.tableData = response.body.records
        //   this.total = response.body.total
        // } else {
        //   this.total = this.qadOptions.length
        //   let arrList = []
        //   if(this.total >= this.listQuery.current * 10) {
        //     this.qadOptions.slice(((this.listQuery.current - 1)*10), ((this.listQuery.current - 1)*10) + 9).forEach((data)=>{
        //       arrList.push({
        //         appName: this.appName,
        //         adName: data.adName,
        //         appId: 0,
        //         adId: 0,
        //         dateYmd: this.listQuery.dateYMd,
        //         actualRevenue: 0,
        //         actualRatio: 0,
        //         showRevenue: 0,
        //         ecpm: 0,
        //         askNumber: 0,
        //         exposureNumber: 0,
        //         clickNumber: 0,
        //         clickRate: 0,
        //       })
        //     })
        //     this.tableData = arrList
        //   } else {
        //     console.log(4444)
        //     let totals = parseInt(((this.total / 10)).toString().substring(2))
        //     console.log('-' + totals)
        //     console.log(this.qadOptions.slice(parseInt(('-' + totals))))
        //     this.qadOptions.slice(parseInt(('-' + totals))).forEach((data)=>{
        //       arrList.push({
        //         appName: this.appName,
        //         adName: data.adName,
        //         appId: 0,
        //         adId: 0,
        //         dateYmd: this.listQuery.dateYMd,
        //         actualRevenue: 0,
        //         actualRatio: 0,
        //         showRevenue: 0,
        //         ecpm: 0,
        //         askNumber: 0,
        //         exposureNumber: 0,
        //         clickNumber: 0,
        //         clickRate: 0,
        //       })
        //     })
        //     this.tableData = arrList
        //   }
        // }
      })
    },
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getList()
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    handleFoms() {
      this.dialogFormVisible1 = true
      this.resetFomes()
      this.dialogStatus1 = 'create'
      this.$nextTick(() => {
        this.$refs['dataForms'].clearValidate()
      })
    },
    createDataTab() {
      console.log(this.fomrs)
      let para = {
        adId:this.fomrs.adId ? this.fomrs.adId.id : '',
        appId:this.fomrs.appId.id,
        dateYMd:this.fomrs.dateYMd,
        sysType:this.fomrs.sysType
      }
      if(!para.appId || !para.dateYMd) {
        this.$message({
          showClose: true,
          message: '请选择应用或日期',
          type: 'error'
        });
        return
      }
       createTabs(para).then(() => {
        //this.tableData.unshift(this.temp)
        this.getList();
        this.dialogFormVisible1 = false
        this.$notify({
          title: 'Success', 
          message: '录入成功',
          type: 'success',
          duration: 2000
        })
      })
      // this.$refs['dataForms'].validate((valid) => {
      //   if (valid) {
      //     createTabs(para).then(() => {
      //       //this.tableData.unshift(this.temp)
      //       this.getList();
      //       this.dialogFormVisible1 = false
      //       this.$notify({
      //         title: 'Success',
      //         message: '录入成功',
      //         type: 'success',
      //         duration: 2000
      //       })
      //     })
      //   }
      // })
    },
    datechange(){
      console.log(this.listQuery.dateYMd);
      // this.dateYMd = this.listQuery.dateYMd
      this.listQuery.current = 1;
      this.getList();

    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          // console.log(this.temp);
          let para = {}
          for (const key in this.temp) {
              if(key === 'adName') {
                console.log(this.temp)
                para.adName = this.temp[key].adName
                para.adId = this.temp[key].id
              } else if(key === 'appName') {
                para.appName = this.temp[key].name
                para.appId = this.temp[key].id
              } else {
                para[key] = this.temp[key]
              }
          }
          console.log(para)
          saveApi(para).then(() => {
            //this.tableData.unshift(this.temp)
            this.getList();
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: '创建成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    resetFomes() {
      this.fomrs = {
        appId: '',
        dateYMd: '',
        fomrs:''
      }
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        appId: '',
        adId: '',
        dateYMd: '',
        actualRevenue: '',
        actualRatio: '',
        showRevenue: '',
        ecpm: '',
        askNumber: '',
        exposureNumber: '',
        clickNumber: '',
        clickRate: '',
        adName: '',
        sysType:'',
        exposureRate:''
      }
    },

    handleUpdate(index, row) {
      console.log(row)
      this.temp = Object.assign({}, row) // copy obj
      this.temp.timestamp = new Date(this.temp.timestamp)
      if(!row.actualRatio) {
        this.temp.actualRatio = '1000'
      }
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.temp)
          saveApi(tempData).then(() => {
            const index = this.tableData.findIndex(v => v.id === this.temp.id)
            this.tableData.splice(index, 1, this.temp)
            this.dialogFormVisible = false
            this.getList();
            this.$notify({
              title: 'Success',
              message: '修改成功',
              type: 'success',
              duration: 2000
            })
          })
        }
      })
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delApi(row).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.tableData.splice(index, 1)
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
}

</script>

<style scoped>


</style>
