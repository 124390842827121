import request from '@/utils/request'

export function listApi(data) {
    return request({
        url: '/advert/apps/list',
        method: 'post',
        data
    })
}

export function addApi(data) {
    return request({
        url: '/advert/apps/add',
        method: 'post',
        data
    })
}

export function updateApi(data) {
    return request({
        url: '/advert/apps/edit',
        method: 'post',
        data
    })
}
export function delApi(data) {
    return request({
        url: '/advert/apps/del',
        method: 'post',
        data
    })
}

export function listAllApi(data) {
    return request({
        url: '/advert/apps/list',
        method: 'post',
        data
    })
}

